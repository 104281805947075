
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import SmartBarcodeLogo from '@/components/svg/SmartBarcodeLogo.vue'
import LoziLogo from '@/components/svg/LoziLogo.vue'
import { sendResetPasswordRequest, setNewPassword } from '@/utils/api'
import errorHandler from '@/utils/errorHandler'
import { FETCH_PROFILE } from '@/store/actions'
import { validateEmail, validatePassword } from '@/utils/validator'
import AppDialog from '@/components/common/AppDialog.vue'
import { openMessage } from '@/utils/utils'
@Options({
  components: { SmartBarcodeLogo, LoziLogo, AppDialog },
  directives: { maska },
  name: 'ForgotPassword',
})
export default class ForgotPassword extends Vue {
  emailInvalidMsg = ''
  pwdInvalidMsg = ''
  pwdNotBeSameMsg = ''
  sendingResetPassDialog = false
  isLoading = false

  forgotPwdFormModel = {
    email: '',
  }

  pwdSetFormModel = {
    password: '',
    confirmPassword: '',
  }

  emailValidate = (rule: string, value: string, callback: Function) => {
    if (!validateEmail(value)) {
      callback(new Error(this.emailInvalidMsg))
    } else {
      callback()
    }
  }

  passwordValidate = (rule: string, value: string, callback: Function) => {
    if (!validatePassword(value)) {
      callback(new Error(this.pwdInvalidMsg))
    } else {
      callback()
    }
  }

  notSamePasswordValidate = (rule: string, value: string, callback: Function) => {
    if (this.pwdSetFormModel.password !== this.pwdSetFormModel.confirmPassword) {
      callback(new Error(this.pwdNotBeSameMsg))
    } else {
      callback()
    }
  }

  forgotPwdRules = {
    email: [{ validator: this.emailValidate, trigger: 'blur' }],
  }

  newPwdRules = {
    password: [{ validator: this.passwordValidate, trigger: 'blur' }],
    confirmPassword: [{ validator: this.notSamePasswordValidate, trigger: 'blur' }],
  }

  get isForgotPasswordPage(): boolean {
    return this.$route.name === 'forgot-password'
  }

  get isNewPasswordSetPage(): boolean {
    return this.$route.name === 'new-password'
  }

  onFinishForgotPwd() {
    this.$router.push({ name: 'home' })
  }

  async doForgotPwd() {
    this.isLoading = true
    await sendResetPasswordRequest(this.forgotPwdFormModel.email)
      .then(() => (this.sendingResetPassDialog = true))
      .catch((err) => errorHandler(err))
      .finally(() => (this.isLoading = false))
  }

  onForgotPwd() {
    this.$refs.forgotPwdFormRef.validate((valid: string) => {
      if (valid) this.doForgotPwd()
      else return false
    })
  }

  async doPwdSet() {
    this.isLoading = true
    await setNewPassword({
      token: this.$route.query.token,
      email: this.$route.query.email,
      password: this.pwdSetFormModel.password,
    })
      .then(() => {
        // fetch profile for loading user info
        this.$store.dispatch(FETCH_PROFILE)

        // notify success
        openMessage(this.$t('account.change_password_successfully'), 'success')

        // redirect to home
        this.onFinishForgotPwd()
      })
      .catch((err) => errorHandler(err))
      .finally(() => (this.isLoading = false))
  }

  onPasswordSet() {
    this.$refs.passwordSetFormRef.validate((valid: string) => {
      if (valid) {
        this.doPwdSet()
      } else {
        return false
      }
    })
  }

  created() {
    this.emailInvalidMsg = this.$t('account.email_invalid')
    this.pwdInvalidMsg = this.$t('account.password_violate_validation_rule')
    this.pwdNotBeSameMsg = this.$t('account.password_and_confirm_password_not_same')
  }
}
