<template>
  <div v-loading.fullscreen="isLoading" class="flex flex-col min-h-screen">
    <div class="text-center flex-grow">
      <SmartBarcodeLogo class="password-page__lozi-logo" />

      <div class="password-page__form w-80 pt-20 text-left m-auto" v-if="isForgotPasswordPage">
        <div class="text-center text-lg font-semibold">
          {{ $t('account.reset_password') }}
        </div>
        <el-form
          status-icon
          label-position="top"
          :model="forgotPwdFormModel"
          :rules="forgotPwdRules"
          ref="forgotPwdFormRef"
        >
          <el-form-item :label="$t('account.email')" prop="email">
            <el-input v-model="forgotPwdFormModel.email" autocomplete="on"></el-input>
          </el-form-item>

          <el-form-item class="text-center pt-4">
            <el-button type="primary uppercase" @click="onForgotPwd">
              {{ $t('account.send_reset_password_request') }}
            </el-button>
          </el-form-item>
        </el-form>
      </div>

      <div class="password-page__form w-80 pt-20 text-left m-auto" v-if="isNewPasswordSetPage">
        <el-form
          status-icon
          label-position="top"
          :model="pwdSetFormModel"
          :rules="newPwdRules"
          ref="passwordSetFormRef"
        >
          <div class="text-center text-lg font-semibold">
            {{ $t('account.new_password_set') }}
          </div>
          <el-form-item :label="$t('account.password')" prop="password">
            <el-input type="password" v-model="pwdSetFormModel.password"></el-input>
          </el-form-item>

          <el-form-item :label="$t('account.confirm_password')" prop="confirmPassword">
            <el-input type="password" v-model="pwdSetFormModel.confirmPassword"></el-input>
          </el-form-item>
          <el-form-item class="text-center pt-4">
            <el-button type="primary uppercase" @click="onPasswordSet">
              {{ $t('account.reset_password') }}
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <div class="mb-6 text-center">
      <LoziLogo />
    </div>
  </div>

  <el-dialog
    custom-class="el-dialog--no-heading"
    width="90%"
    top="0"
    :model-value="sendingResetPassDialog"
    :close-on-click-modal="false"
    :show-close="false"
    @closed="sendingResetPassDialog = false"
  >
    <div>
      <!-- diaglog-message  -->
      <div class="mx-3 mb-6">
        {{ $t('account.sending_reset_password_message') }}
      </div>

      <br />

      <div class="text-right">
        <el-button type="primary" @click="onFinishForgotPwd">
          {{ $t('ok') }}
        </el-button>
      </div>
    </div>
  </el-dialog>
  <AppDialog />
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { maska } from 'maska'
import SmartBarcodeLogo from '@/components/svg/SmartBarcodeLogo.vue'
import LoziLogo from '@/components/svg/LoziLogo.vue'
import { sendResetPasswordRequest, setNewPassword } from '@/utils/api'
import errorHandler from '@/utils/errorHandler'
import { FETCH_PROFILE } from '@/store/actions'
import { validateEmail, validatePassword } from '@/utils/validator'
import AppDialog from '@/components/common/AppDialog.vue'
import { openMessage } from '@/utils/utils'
@Options({
  components: { SmartBarcodeLogo, LoziLogo, AppDialog },
  directives: { maska },
  name: 'ForgotPassword',
})
export default class ForgotPassword extends Vue {
  emailInvalidMsg = ''
  pwdInvalidMsg = ''
  pwdNotBeSameMsg = ''
  sendingResetPassDialog = false
  isLoading = false

  forgotPwdFormModel = {
    email: '',
  }

  pwdSetFormModel = {
    password: '',
    confirmPassword: '',
  }

  emailValidate = (rule: string, value: string, callback: Function) => {
    if (!validateEmail(value)) {
      callback(new Error(this.emailInvalidMsg))
    } else {
      callback()
    }
  }

  passwordValidate = (rule: string, value: string, callback: Function) => {
    if (!validatePassword(value)) {
      callback(new Error(this.pwdInvalidMsg))
    } else {
      callback()
    }
  }

  notSamePasswordValidate = (rule: string, value: string, callback: Function) => {
    if (this.pwdSetFormModel.password !== this.pwdSetFormModel.confirmPassword) {
      callback(new Error(this.pwdNotBeSameMsg))
    } else {
      callback()
    }
  }

  forgotPwdRules = {
    email: [{ validator: this.emailValidate, trigger: 'blur' }],
  }

  newPwdRules = {
    password: [{ validator: this.passwordValidate, trigger: 'blur' }],
    confirmPassword: [{ validator: this.notSamePasswordValidate, trigger: 'blur' }],
  }

  get isForgotPasswordPage(): boolean {
    return this.$route.name === 'forgot-password'
  }

  get isNewPasswordSetPage(): boolean {
    return this.$route.name === 'new-password'
  }

  onFinishForgotPwd() {
    this.$router.push({ name: 'home' })
  }

  async doForgotPwd() {
    this.isLoading = true
    await sendResetPasswordRequest(this.forgotPwdFormModel.email)
      .then(() => (this.sendingResetPassDialog = true))
      .catch((err) => errorHandler(err))
      .finally(() => (this.isLoading = false))
  }

  onForgotPwd() {
    this.$refs.forgotPwdFormRef.validate((valid: string) => {
      if (valid) this.doForgotPwd()
      else return false
    })
  }

  async doPwdSet() {
    this.isLoading = true
    await setNewPassword({
      token: this.$route.query.token,
      email: this.$route.query.email,
      password: this.pwdSetFormModel.password,
    })
      .then(() => {
        // fetch profile for loading user info
        this.$store.dispatch(FETCH_PROFILE)

        // notify success
        openMessage(this.$t('account.change_password_successfully'), 'success')

        // redirect to home
        this.onFinishForgotPwd()
      })
      .catch((err) => errorHandler(err))
      .finally(() => (this.isLoading = false))
  }

  onPasswordSet() {
    this.$refs.passwordSetFormRef.validate((valid: string) => {
      if (valid) {
        this.doPwdSet()
      } else {
        return false
      }
    })
  }

  created() {
    this.emailInvalidMsg = this.$t('account.email_invalid')
    this.pwdInvalidMsg = this.$t('account.password_violate_validation_rule')
    this.pwdNotBeSameMsg = this.$t('account.password_and_confirm_password_not_same')
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  &.password-page {
    &__lozi-logo {
      margin-top: 60px;
      width: 550px;
      max-width: 100%;
      height: auto;
    }
    &__form {
      .el-form-item__label {
        padding: 0;
      }
    }
  }
}
</style>
